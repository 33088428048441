<template>
  <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
    <v-row class="no-gutters">
      <v-col
        xl="8"
        offset-xl="2"
        md="10"
        offset-md="1"
        sm="12"
        offset-sm="0"
        class="py-0"
      >
        <v-card
          :class="
            $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
          "
          style="border-radius:6px"
        >
          <v-toolbar
            flat
            color="primary"
            height="120px"
            dark
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'rounded-t-0'
                : 'rounded-t-lg pl-3'
            "
          >
            <v-toolbar-title class="d-flex align-center">
              <v-icon large>
                mdil-format-align-left
              </v-icon>
              <span class="pl-6 text-h4">{{ $t("yazilar") }}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="pa-6"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-16 pb-16 ' : ''"
          >
            <div v-for="(yazi, index) in yazilar" :key="index" class="pb-16">
              <div class="text-h3 pb-5">
                {{ yazi.baslik }}
              </div>
              <tiptap-vuetify
                v-model="yazi.icerik"
                flat
                :extensions="extensions"
              />
            </div>
          </v-card-text>
          <div :class="$vuetify.breakpoint.smAndDown ? 'fixedCardActions' : ''">
            <v-divider />
            <v-card-actions
              class=" pa-0"
              :class="$vuetify.breakpoint.smAndDown ? 'white' : 'primary'"
            >
              <v-spacer />
              <v-btn
                block
                large
                :color="$vuetify.breakpoint.smAndDown ? 'primary' : 'white'"
                text
                class="font-weight-bold text-capitalize"
                @click="save()"
              >
                {{ $t("kaydet") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      multi-line
      timeout="2000"
      absolute
      centered
      color="primary"
      elevation="24"
      rounded="pill"
    >
      <div class="text-center">
        {{ snackbarText }}
      </div>
    </v-snackbar>
    <v-overlay :value="snackbar" :z-index="0" />
  </v-container>
</template>
<script>
import { veri, depo } from "./../db";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  name: "YazilarView",
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    snackbar: false,
    snackbarText: "",
    yazilar: []
  }),
  firebase: {
    yazilar: veri.ref("yazilar")
  },
  created() {},
  methods: {
    save() {
      veri
        .ref("yazilar/")
        .set(this.yazilar)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = this.$t("veriBasarili");
        });
    }
  }
};
</script>
<style lang="scss">
.fixedCardActions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: unset !important;
}
</style>
